export default class IframeCartePostale {
    constructor() {
        IframeCartePostale.bind()
        IframeCartePostale.onSuccessSubmit()
    }

    static bind() {
        if ($('[data-form-carte-postale-iframe]').length > 0) {
            const iframeUrl = $('[data-form-carte-postale-iframe]').attr('src')

            //On init
            setTimeout(IframeCartePostale.reload(iframeUrl), 500)

            //On text input change
            $('[data-form-carte-postale-form] #field_message').on('blur', () => {
                IframeCartePostale.reload(iframeUrl)
            })

            //On radio input change
            $('[data-form-carte-postale-form] .fond-carte-postale-form input').on('change', () => {
                IframeCartePostale.reload(iframeUrl)
            })
        }
    }

    static reload(iframeUrl) {
        iframeUrl += '?preview=1'
        const message = $('[data-form-carte-postale-form] #field_message').val()
        let picture = null

        if ($('[data-form-carte-postale-form] .fond-carte-postale-form input:checked').length > 0) {
            picture = $('[data-form-carte-postale-form] .fond-carte-postale-form input:checked')
                .parent()
                .find('img')
                .attr('src')
            iframeUrl += `&picture=${encodeURI(picture)}`
        }

        if (message != null && message !== '') {
            iframeUrl += `&message=${encodeURI(message)}`
        }
        $('[data-form-carte-postale-iframe]').attr('src', iframeUrl)
    }

    static onSuccessSubmit() {
        if ($('.frm_message').length > 0) {
            $('[data-form-carte-postale-iframe]').hide()
            $('.postal-card-template > div').css('min-height', 'auto')
        }
    }
}
