import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class Infolive {
    constructor() {
        const clock = $('[data-id="clock"]')
        setInterval(() => {
            Infolive.clock(clock)
        }, 30000)
        Infolive.infolive()
        Infolive.adsSlider()
    }

    static clock(clock) {
        $.ajax({
            url: Customer.ajaxURL,
            type: 'post',
            data: {
                action: 'ajax_clock',
            },
            success: (result) => {
                clock.html(result)
            },
        })
    }

    static infolive() {
        const trigger = '[data-trigger="wpiris-infolive"]'
        const location_trigger = '[data-trigger="infolive-item"]'
        const location_item = '[data-element="infolive-item"]'
        const cursor = '[data-element="infolive-cursor"]'

        $(trigger).on('click', function () {
            $('body').toggleClass('infolive-open')
            $(this).find('a').toggleClass('ficore ficore--before ficore--close')
            $(this).find('a').toggleClass('fi fi--before fi--infolive')
        })

        $(location_trigger).on('click', function () {
            if ($(this).hasClass('infolive__locations__item--open')) {
                $(this).toggleClass('infolive__locations__item--open')
                const thisContent = $(this).find('[data-element="infolive-item-content"]')
                $(thisContent).animate(
                    {
                        height: thisContent.height(0),
                    },
                    200,
                    'linear',
                )
            } else {
                $(location_trigger).removeClass('infolive__locations__item--open')
                $(this).toggleClass('infolive__locations__item--open')
                const content = $(location_trigger).find('[data-element="infolive-item-content"]')
                $(content).animate(
                    {
                        height: content.height(0),
                    },
                    200,
                    'linear',
                )

                const thisContent = $(this).find('[data-element="infolive-item-content"]')
                thisContent.animate(
                    {
                        height: $(thisContent).get(0).scrollHeight,
                    },
                    200,
                    'linear',
                )
            }

            const item_top = $(this).position().top
            $(cursor).css('top', item_top + 2)
        })

        $(location_item).hover(function () {
            const item_top = $(this).position().top
            $(cursor).css('top', item_top + 2)
        })
    }

    static async adsSlider() {
        const swiperEl = '.infolive__ads'

        const options = {
            slidesPerView: 1,
            spaceBetween: 0,
            mousewheel: {
                forceToAxis: true,
            },
            freeMode: {
                enabled: true,
                sticky: true,
            },
            pagination: {
                el: '.infolive__ads__pagination',
                type: 'bullets',
                clickable: true,
            },
            grabCursor: true,
            speed: 600,
            lazy: true,
            autoplay: {
                delay: 5000,
            },
        }

        if ($(swiperEl).find('.swiper-wrapper').length) {
            await Swiper.create(document.querySelector('.infolive__ads'), options)
        }
    }
}
