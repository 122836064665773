import 'jquery'
import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

import HeaderSlider from '@scripts/components/headerSlider'
import IframeCartePostale from '@scripts/components/iframeCartePostale'
import Infolive from '@scripts/components/infolive'
import Navbar from '@scripts/components/navbar'

document.addEventListener('DOMContentLoaded', () => {
    new Navbar()
    new HeaderSlider()
    new IframeCartePostale()
    new Infolive()
})
