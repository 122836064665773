export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.burger()
        Navbar.langsSwitcher()
        Navbar.rootVarHeight()

        addEventListener('resize', Navbar.rootVarHeight())
        addEventListener('orientationchange', Navbar.rootVarHeight())
    }

    static sticky() {
        let lastScrollTop = 0
        const $navbar = $('[data-id="wpiris-navbar"]')
        const logoHome = '[data-element="logo-home"]'
        const logoHomeWhite = '[data-element="logo-home-white"]'

        if (window.matchMedia('(max-width: 650px)').matches) {
            if (Customer.isFrontPage) {
                if ($navbar.hasClass('navbar--onscroll')) {
                    $(logoHome).removeClass('hidden')
                    $(logoHomeWhite).addClass('hidden')
                } else {
                    $(logoHome).addClass('hidden')
                    $(logoHomeWhite).removeClass('hidden')
                }
            }
        }

        $(window).on('load scroll', function () {
            const scrollTop = $(this).scrollTop()

            if (scrollTop >= lastScrollTop && scrollTop > $navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            if (scrollTop > 100) {
                $navbar.addClass('navbar--onscroll')
            } else {
                $navbar.removeClass('navbar--onscroll')
            }

            if (window.matchMedia('(max-width: 650px)').matches) {
                if ($navbar.hasClass('navbar--onscroll')) {
                    $(logoHome).removeClass('hidden')
                    $(logoHomeWhite).addClass('hidden')
                } else {
                    $(logoHome).addClass('hidden')
                    $(logoHomeWhite).removeClass('hidden')
                }
            }
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
        })

        $(more).on('click', function () {
            $(this).next(submenu).addClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        const current_lang_value = current_lang.text()

        //On init
        $(window).on('load', () => {
            window.setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')
                if (current_language === 'auto') {
                    current_language = 'fr'
                }

                $('.tools__item__languages__langs > a').each(function () {
                    if ($(this).text().toUpperCase() === current_language.toUpperCase()) {
                        $(this).hide()
                    }
                })

                $(current_lang).text(current_language.toUpperCase())
            }, 500)
        })

        //Open submenu
        $(current_lang).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Change Navbar Language on click
        $('.tools__item__languages__langs > a').on('click', function () {
            $('.tools__item__languages__langs > a').show()
            $(this).hide()
            $(list_langs).toggleClass('tools__item__languages__langs--open')
            $(current_lang).text($(this).text())
        })
    }

    static rootVarHeight() {
        if (window.matchMedia('(max-width: 650)').matches) {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
        }
    }
}
